body::-webkit-scrollbar {display: none;}
.table{white-space: pre;}
.custom-scrollbar 
{
    direction: rtl;
    text-align: left;
}
.content::-webkit-scrollbar {height: 20px; }
::-webkit-scrollbar{width: 20px;}
::-webkit-scrollbar-thumb 
{
    background-color: #d6dee1;
    border-radius: 80px;
    border: 8px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {background-color: #a8bbbf;}


.table-container::-webkit-scrollbar{width: 20px;}
.table-container::-webkit-scrollbar-thumb 
{
    background-color: #3baffc;
    border-radius: 80px;
    border: 7px solid transparent;
    background-clip: content-box;
}.table-container::-webkit-scrollbar-thumb:hover {background-color: #4ad2ff	;}



.tabs::-webkit-scrollbar{width: 20px;}
.tabs::-webkit-scrollbar-thumb 
{
    background-color: #3baffc;
    border-radius: 80px;
    border: 7px solid transparent;
    background-clip: content-box;
}.tabs::-webkit-scrollbar-thumb:hover {background-color: #4ad2ff;}

.scrollvert::-webkit-scrollbar{width: 20px;}
.scrollvert::-webkit-scrollbar-thumb 
{
    background-color: #3baffc;
    border-radius: 80px;
    border: 7px solid transparent;
    background-clip: content-box;
}.scrollvert::-webkit-scrollbar-thumb:hover {background-color: #4ad2ff;}

.scrollCont
{
    overflow-y: scroll;
    height: 100%;
}
.scrollCont::-webkit-scrollbar{width: 20px;}
.scrollCont::-webkit-scrollbar-thumb 
{
    background-color: rgb(255, 78, 75);
    border-radius: 80px;
    border: 7px solid transparent;
    background-clip: content-box;
}.scrollCont::-webkit-scrollbar-thumb:hover {background-color: #ff827e}

.scrollTabs
{
    overflow-x: scroll !important ;
    overflow-y : hidden !important;
}
.scrollTabs::-webkit-scrollbar{height: 22px;}
.scrollTabs::-webkit-scrollbar-thumb 
{
    background-color: rgb(255, 78, 75);
    background-clip: content-box;
}.scrollTabs::-webkit-scrollbar-thumb:hover {background-color: #ff827e}

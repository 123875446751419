.navBarHeight{height : calc(100% - 90px);}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button 
{
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {-moz-appearance: textfield;}
.backDiv
{
    background-image: url("Images/Background.png");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
}
.fullHeightPage{height: 100vh;}
.fullHeight{height: 100%;}
.Select-value-label, .Select-option {white-space: pre-wrap;}
.fullwidth{width : 100%;}
.fitt
{
    height: 100%;
    width: 100%;
}
.fontPosition{font-size: 90%;}
.dropDownDownloadStyle
{
    border: 2px solid #CCCCCC;
    background-color: white;
    border-radius: 5px;

}

.navbar{zoom : 150%;}
.hideDiv{ transition: opacity 0.3s linear; opacity: 0;}
.showDiv{transition: opacity 0.3s linear;opacity : 1;}
.hideBox
{
    transition: 0.3s linear;
    position: relative;
    top : -140px;
}
.showBox
{
    transition: 0.3s linear;

    position: relative;
    top: -50px;
}
#tableStyle{zoom : 85%}
.disabledButtons {
    pointer-events: none;
    opacity: 0.4;
}
.infoButton:hover .msg {visibility: visible;}
.darkenDiv{position:absolute;top:0%;right:0%;width:100%;height:100%;background-color:black;opacity:0.7;z-index: 5;}
.divImagePivot{position:absolute;top:0%;left:25%;width:50%;height:50%;z-index: 15;}

.circleUpdate
{
    height: 15px;
    width: 15px ;
    border: 2px solid #d3d3d3;

    background-color: yellow;
    border-radius: 50%;
    display: inline-block;
}

.dotWarn
{
    height: 25px;
    width: 25px ;
    border: 2px solid #d3d3d3;
    border-radius: 50%;
    display: inline-block;
    left: 10%;
    position: relative;
}
.sortableHelper {z-index: 47;}
.grayBackcolor {background-color: rgb(250, 250, 250);}
.grayBackcolor:hover {background-color: rgb(240, 240, 240);}
.bordered { border: 0.1px solid hsl(0deg 0% 29% / 25%) !important; }
.canEated
{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow : hidden;
}
/*
other - 1
darken - 5
divImagePivot - 10
table - 15
fullScreen - 20
modalprops - 25
modalSplit-30
modalFilter-35
modalCustom- 45
modalGen-50
modalWarning - 65
filterSelect - 60
*/
th {font-weight: normal;z-index: 1;}
.Stickyright
{
    position: sticky;
    background-color: white;
    z-index: 4;
}
.Stickyleft
{
    position: sticky;
    background-color: white;
    z-index: 4;
}

.tr_hovered:hover > td
{
    background-color: rgb(240, 240, 240) !important;
}
.cellPivot:hover
{
    background: rgb(205, 231, 254) !important;
}
.cellSpoti:hover
{
    background: rgb(165, 245, 175) !important;
}


.filtertable {
    table-layout: fixed;
    width: 100%;
  }
  
.filtertd {
    overflow: hidden;
    word-wrap: break-word;
    white-space: normal;
    text-overflow: ellipsis;
}

.td_center_text
{
    display: flex;
    align-items: center;
    justify-content: center; 
    height: 3em; 
}